export enum ActionPostMessageEnum {
  SET_USER_DATA = 'SET_USER_DATA',
  SHOW_ALL_DRIVER = 'SHOW_ALL_DRIVER',
  SELECT_DRIVER = 'SELECT_DRIVER',
  FILTER_DRIVER = 'FILTER_DRIVER',
  UPDATE_DRIVER = 'UPDATE_DRIVER',
  RECENTER = 'RECENTER',
  SHOW_ALL_ORDERS = 'SHOW_ALL_ORDERS',
  SELECT_ORDER = 'SELECT_ORDER',
  FLY_TO_ORDER = 'FLY_TO_ORDER',
  RESET_ORDER = 'RESET_ORDER',
  HAS_POOLING = 'HAS_POOLING',
  POST_NEW_ROUTE = 'POST_NEW_ROUTE',
  SET_HUB_LOCATION = 'SET_HUB_LOCATION',
  SELECT_POOL = 'SELECT_POOL',
  REMOVE_ORDER = 'REMOVE_ORDER',
  ORDER_DETAIL_ADMIN = 'ORDER_DETAIL_ADMIN',
  SET_TOKEN_HCU = 'SET_TOKEN_HCU',
  SET_ISOCHRONE = 'SET_ISOCHRONE',
  SET_TOKEN = 'SET_TOKEN',
}

export enum ActionPostMessageNativeAppEnum {
  WEBVIEW_NAVIGATE_BACK = 'WEBVIEW_NAVIGATE_BACK',
  WEBVIEW_OPEN_CHAT_PAGE = 'WEBVIEW_OPEN_CHAT_PAGE',
  WEBVIEW_INITIATE_CALL = 'WEBVIEW_INITIATE_CALL',
}
