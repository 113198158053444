import { AxiosRequestConfig } from 'axios'
import axiosInstanceApiGateway from 'config/apiService'

export type GetTrackingDetailResponseType = {
  arrival_estimation: string
  order_status: string
  destination: {
    coordinate: {
      latitude: number
      longitude: number
    }
    address_detail: string
    address_notes: string
  }
  driver: {
    name: string
    plate_number: string
    phone_number: string
  }
}

export type PostGetRouteRequest = {
  locations: {
    lat: number
    lon: number
  }[]
  units: string
  costing: string
  id?: string
}

export type PostGetRouteResponse = {
  data: {
    id: string
    trip: {
      detail: {
        shape: string
        summary: {
          distance: number
          duration: number
        }
      }[]
      locations: {
        lat: number
        lon: number
      }
    }
  }
  error: {
    code: number
    message: string
    status: boolean
  }
}

export type GetRadiusLocationResponseType = {
  radius: number
}

export const getTrackingDetailAPI = (
  orderId: number,
  config?: AxiosRequestConfig
) =>
  axiosInstanceApiGateway.get<GetTrackingDetailResponseType>(
    `/logistic/v1/buyer/tracking/${orderId}`,
    config
  )

export const postGetRouteInternalAPI = (
  payload: PostGetRouteRequest,
  config?: AxiosRequestConfig
) =>
  axiosInstanceApiGateway.post<PostGetRouteResponse>(
    '/location/internal/v1/route',
    payload,
    config
  )

export const postGetRouteCustomerAPI = (payload: PostGetRouteRequest) =>
  axiosInstanceApiGateway.post<PostGetRouteResponse>(
    '/location/v1/route',
    payload
  )
export const getOrderDetailAdmin = (payload: { orderId: string }) =>
  axiosInstanceApiGateway.get(`/api/v2/order/${payload.orderId}`)

export const getRadiusLocationAPI = () =>
  axiosInstanceApiGateway.get<GetRadiusLocationResponseType>(
    `/location/internal/v1/location-geofencing/radius/address`
  )
