import { Box } from '@chakra-ui/react'
import { hubIcon } from 'components/TrackingMap'
import { POLYGON_COLOR_BY_SLA } from 'constants/index'
import { Marker, Polygon } from 'react-leaflet'
import { IsochroneLocationCustom } from 'store/isochroneAll'
import { usePoolingMapStore } from 'store/poolingMap'
import { mapCoordinatesToLatLng } from 'utils/map'

const Isochrone = () => {
  const { isochroneList } = usePoolingMapStore()
  const getPolygonColor = (isoLoc: IsochroneLocationCustom): string => {
    if (isoLoc.service_level_name?.includes(POLYGON_COLOR_BY_SLA[0].slaName)) {
      return POLYGON_COLOR_BY_SLA[0].color
    }

    if (isoLoc.service_level_name?.includes(POLYGON_COLOR_BY_SLA[1].slaName)) {
      return POLYGON_COLOR_BY_SLA[1].color
    }

    return POLYGON_COLOR_BY_SLA[2].color
  }
  const getPolygonElavation = (isoLoc: IsochroneLocationCustom): number => {
    if (isoLoc.service_level_name?.includes(POLYGON_COLOR_BY_SLA[0].slaName)) {
      return 2
    }

    if (isoLoc.service_level_name?.includes(POLYGON_COLOR_BY_SLA[1].slaName)) {
      return 1
    }

    return 0
  }
  const MapObjects = () =>
    isochroneList.map((item) => {
      const key = `${item.location_id} - ${item.service_level_id}`

      const polygonCoordinates = item.polygons.coordinates?.length
        ? item.polygons.coordinates[0]
        : []

      const locationPosition = {
        lat: Number(item.location_latitude),
        lng: Number(item.location_longitude),
      }
      const polygonOptions = {
        fillColor: getPolygonColor(item),
        strokeColor: getPolygonColor(item),
        zIndex: getPolygonElavation(item),
      }

      return (
        <Box key={key}>
          <Polygon
            positions={mapCoordinatesToLatLng(polygonCoordinates)}
            pathOptions={polygonOptions}
          />
          <Marker icon={hubIcon} position={locationPosition} />
        </Box>
      )
    })
  return <>{MapObjects()}</>
}

export default Isochrone
