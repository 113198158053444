import { ActionPostMessageEnum } from 'constants/postMessage'
import useReceiveMessage from 'hooks/useReceiveMessage'
import { LatLngTuple } from 'leaflet'
import { useState } from 'react'
import { usePoolingMapStore } from 'store/poolingMap'
import { setTokenCookies } from 'utils/cookie'
import { polylineDecode } from 'utils/polylineDecode'
import { DeliveriesType, IsochroneFilterType, ListDeliveriesType } from './type'

const usePoolingMap = () => {
  const {
    selectedOrder,
    setReset,
    setHasPooling,
    allDeliveryOrder,
    setAllDeliveryOrder,
    setSelectedOrder,
    fetchIsochrone,
    setEnableIsochrone,
    enableIsochrone,
  } = usePoolingMapStore()
  const [hubLocation, setHubLocation] = useState<LatLngTuple>([
    -6.175392, 106.827153,
  ])
  const [mapCenter, setMapCenter] = useState<LatLngTuple>([
    -6.175392, 106.827153,
  ])
  const [routes, setRoutes] = useState<[number, number][]>([])

  useReceiveMessage<string>(ActionPostMessageEnum.SET_TOKEN, (data) => {
    setTokenCookies(data.payload)
  })

  useReceiveMessage<ListDeliveriesType>(
    ActionPostMessageEnum.SHOW_ALL_ORDERS,
    (data) => {
      if (
        data?.payload?.delivery_pools &&
        Array.isArray(data.payload.delivery_pools)
      ) {
        const deliveryPools = data?.payload?.delivery_pools
        const allDeliveries = deliveryPools?.flatMap((pool) =>
          pool.deliveries?.map((delivery) => ({
            ...delivery,
            pool_id: pool.pool_id,
            color: pool.color,
            pool_number: pool.pool_number,
          }))
        ) as DeliveriesType
        setAllDeliveryOrder(allDeliveries)
      }
    }
  )

  useReceiveMessage<number>(ActionPostMessageEnum.FLY_TO_ORDER, (data) => {
    const poolId = data.payload
    const order = allDeliveryOrder.find(
      (delivery) => delivery.pool_id === poolId
    )
    if (order) {
      setMapCenter([
        order.destination.address.latitude,
        order.destination.address.longitude,
      ])
    }
  })
  useReceiveMessage<string>(ActionPostMessageEnum.RESET_ORDER, () => {
    setReset()
    setRoutes([])
  })

  useReceiveMessage<string>(ActionPostMessageEnum.HAS_POOLING, (data) => {
    setHasPooling(data.payload)
  })
  useReceiveMessage<{ shapes: string[]; total_distance: number }>(
    ActionPostMessageEnum.POST_NEW_ROUTE,
    (data) => {
      const routesDecoded = data.payload.shapes.map((route) =>
        polylineDecode(route)
      )
      const flattenedRoutes = routesDecoded.flat()
      setRoutes(flattenedRoutes)
    }
  )

  useReceiveMessage<{
    locationSelected: { location_latitude: string; location_longitude: string }
  }>(ActionPostMessageEnum.SET_HUB_LOCATION, (data) => {
    // eslint-disable-next-line camelcase
    const { location_latitude, location_longitude } =
      data.payload.locationSelected
    setHubLocation([Number(location_latitude), Number(location_longitude)])
  })

  useReceiveMessage<DeliveriesType[number]>(
    ActionPostMessageEnum.SELECT_POOL,
    (data) => {
      setSelectedOrder(data.payload)
    }
  )

  useReceiveMessage<IsochroneFilterType>(
    ActionPostMessageEnum.SET_ISOCHRONE,
    (data) => {
      if (data.payload.enableIsochrone) {
        fetchIsochrone(data.payload)
      }
      setEnableIsochrone(data.payload.enableIsochrone)
    }
  )

  return {
    mapCenter,
    hubLocation,
    allDeliveryOrder,
    selectedOrder,
    routes,
    enableIsochrone,
  }
}

export default usePoolingMap
