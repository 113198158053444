import create from 'zustand'
import { withImmer, logger } from 'utils/store'
import {
  createLiveTrackingOrderHCUSlice,
  type LiveTrackingOrderSlice,
} from './liveTrackingOrderSlice'

const useLiveTrackingOrderHCUStore = create<LiveTrackingOrderSlice>(
  logger(withImmer(createLiveTrackingOrderHCUSlice), 'MapStore')
)

export { useLiveTrackingOrderHCUStore }
export type { LiveTrackingOrderSlice }
