export const applicationHealth = '/application/health'
export const isochrone = '/'
export const isochroneAll = '/isochrone'
export const isochroneValidation = '/isochrone-validation'
export const quadrantIsochrone = '/quadrant-isochrone'
export const locationOrder = '/location-order'
export const liveTrackingDriver = '/live-tracking-driver'
export const liveTrackingOrder = 'live-tracking-order/:hubId/:orderId'
export const liveTrackingHCU = 'live-tracking-hcu/:hubId/:orderId'
export const kitchenIsochrone = '/kitchen-isochrone'
