import './trackingOrder.css'
import TrackingMap from 'components/TrackingMap'
import { useEffect, useMemo, useState } from 'react'

import { useMqttStore } from 'store/mqtt'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLiveTrackingOrderHCUStore } from 'store/liveTrackingHCU'
import FlyMapTo from 'components/TrackingMap/FlyMapTo'
import { RestrictedWebview } from 'components/WebViewSession'
import CompleteOrderImage from 'assets/images/png/order_completed.png'
import RestrictedImage from 'assets/images/png/restricted_page.png'

import { setTokenCookies } from 'utils/cookie'
import useReceiveMessage from 'hooks/useReceiveMessage'
import { ActionPostMessageEnum } from 'constants/postMessage'
import Content from './Content'
import OrderSummary from '../LiveTrackingOrder/OrderSummary'

/**
 * @default Monas Lat Long
 */
const placeholderPosition: [number, number] = [-6.175392, 106.827153]

const LiveTrackingHCU = () => {
  const navigate = useNavigate()
  const { connectStatus, mqttSubcribe, isSubcribed } = useMqttStore()
  const { hubId, orderId } = useParams<{ hubId: string; orderId: string }>()
  const { isCompleted, isCancelled, order, fetchGetOrderDetailAdmin } =
    useLiveTrackingOrderHCUStore()

  const urlLocation = useLocation()
  const searchParams = new URLSearchParams(urlLocation.search)
  const userIDAdmin = searchParams.get('userId')
  const [token, setToken] = useState('')

  useReceiveMessage<string>(ActionPostMessageEnum.SET_TOKEN_HCU, (data) => {
    if (data.payload) {
      setTokenCookies(data.payload)
      setToken(data.payload)
    }
  })

  useReceiveMessage<{
    longitude: number
    latitude: number
    addressDetail: string
    address: string
    driverNote: string
    status: string
  }>(ActionPostMessageEnum.ORDER_DETAIL_ADMIN, (data) => {
    if (data.payload) {
      fetchGetOrderDetailAdmin(data.payload)
    }
  })

  const mqqttAuth = useMemo(() => {
    if (token !== '' && userIDAdmin) {
      return {
        username: `admin_${userIDAdmin}`,
        password: token,
      }
    }

    return null
  }, [token, userIDAdmin])

  useEffect(() => {
    if (
      connectStatus === 'Connected' &&
      !isSubcribed &&
      hubId &&
      orderId &&
      !!userIDAdmin
    ) {
      mqttSubcribe({
        username: `admin_${userIDAdmin}`,
        topic: `location/hub/${hubId}/order/${orderId}`,
        qos: 0,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubcribed, connectStatus])

  const location = useMemo(
    () =>
      order
        ? ([order.lat, order.lon] as [number, number])
        : placeholderPosition,
    [order]
  )

  if (!mqqttAuth) {
    return (
      <RestrictedWebview
        goBack={() => {
          navigate(-1)
        }}
      />
    )
  }

  if (isCompleted) {
    return (
      <OrderSummary
        title="Pesanan sudah selesai"
        message="Driver sudah selesai mengantar pesanan Anda, silahkan cek pesanan Anda"
        image={CompleteOrderImage}
      />
    )
  }

  if (isCancelled) {
    return (
      <OrderSummary
        title="Pesanan anda telah dibatalkan"
        message="Halaman tidak bisa di akses"
        image={RestrictedImage}
      />
    )
  }

  return (
    <>
      <TrackingMap
        apiKey={process.env.REACT_APP_MAPS_KEY_LIVE_TRACKING_ORDER || ''}
        key={location[0].toString()}
        className="live-tracking-order"
        mqttUsername={mqqttAuth.username}
        mqttPassword={mqqttAuth.password}
        hubId={hubId}
        orderId={orderId}
        center={location}
        zoom={10}
      >
        <FlyMapTo position={location} zoom={10} />
        <Content />
      </TrackingMap>
      {/* <InformationDrawer /> */}
    </>
  )
}

export default LiveTrackingHCU
